export class Hum {
  selector: HTMLInputElement;
  onClick: any;
	flag : boolean;
  constructor(_param) {
    this.selector = _param.selector;
    this.onClick = _param.onClick;
		this.flag = true;
  }

  init() {
    this.addEvent();
  }

  addEvent() {
    this.selector.addEventListener('click', (e) => {
      if (this.flag) {
        this.onClick('open');
      } else {
        this.onClick('close');
      }
    });
  }

  open() {
    this.selector.classList.add('is-active');
		this.flag = false;
  }
  close() {
    this.selector.classList.remove('is-active');
		this.flag = true;
  }
}
