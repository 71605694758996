const btns : any = document.querySelectorAll('.js-switch');
const targets: NodeListOf<Element> =document.querySelectorAll('.js-swTarget');


export let tableBtnEvent = () => {
	if(document.querySelector('.js-switch')){
		let switchingBtn = new SwitchingBtn(btns,targets);
		switchingBtn.init();
	}
}

class SwitchingBtn {
	btns;
	targets;
	clickedBtn :number;
	constructor(btns,targets){
		this.btns = btns;
		this.targets = targets;
		this.clickedBtn = 0 ;
	}

	init(){
		this.clickedBtnEvent();
	}

	btnEvent(){
		this.btns.forEach((btn,i) => {
			if(i === this.clickedBtn){
				btn.classList.add('is-active');
				this.targets[i].classList.add('is-active')
			}else{
				btn.classList.remove('is-active');
				this.targets[i].classList.remove('is-active');
			}
		});
	}

	clickedBtnEvent (){
		for (const btn of this.btns) {
			btn.addEventListener('click',()=>{
				this.clickedBtn = Array.prototype.indexOf.call(this.btns,btn);
				this.btnEvent();
			})
		}
	}
}