import { breakPointLG } from '../globalVar';
export let smoothScroll;
let Ease = {
  easeInOut: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // イージング関数https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
};
const duration = 500; // アニメーションの Duration の設定
const pageLinks = document.querySelectorAll('a[href^="#"]');
const pageLinksLength = pageLinks.length;
let offsetTop;
let linktop;
const header =  document.getElementById('js-headerWrap');
smoothScroll =  ()=> {
  //スムーススクロール
	pageLinks.forEach((pageLink,i) => {
    pageLink.addEventListener("click", (e)=> {
        e.preventDefault();
				//マージン処理
				let margin = (pageLink.classList.contains('js-serviceAnchor'))? header.clientHeight : 0;
        setTimeout(() => {
          let href = pageLink.getAttribute("href"); // href属性の値を取得
          let target = href == "#" || href == "" ? "html" : href; //#か空だったらhtmlを返す それ以外はreturn
          let link = document.querySelector(target);
          let rectTop = link.getBoundingClientRect().top; //要素の位置
          offsetTop = window.pageYOffset; // 現在のスクロール距離
          linktop = rectTop + offsetTop - margin; //link先の距離
          let startTime = performance.now(); // アニメーションのループを定義
          let loop =  (nowTime)=> {
            // スタートからの経過時間を取得
            let time = nowTime - startTime; // duration を1とした場合の経過時間を計算
            let normalizedTime = time / duration; // duration に経過時間が達していない場合はアニメーションを実行
            if (normalizedTime < 1) {
              window.scrollTo(0,offsetTop +(linktop - offsetTop) * Ease.easeInOut(normalizedTime)
              ); // 経過時間とイージングに応じてスクロール位置を変更
              requestAnimationFrame(loop); // アニメーションを継続
            } else {
              // duration に経過時間が達したら、アニメーションを終了
              window.scrollTo(0, linktop);
            }
          };
          requestAnimationFrame(loop);
        });
      },
      100
    );
});
}
