export class HumItem {
  selector: HTMLInputElement;
  constructor(_param) {
    this.selector = _param.selector;
  }
  open() {
    this.selector.classList.add('is-active');
  }
  close() {
    this.selector.classList.remove('is-active');
  }
}
