
const home: HTMLElement = document.getElementById('home');
const bgat: HTMLElement = document.getElementById('bgat');
let scrollTop: number = window.pageYOffset;
let windowHeight: number = window.innerHeight;
let targetHeight: number = (bgat) ? bgat.clientHeight : 0 ; 
let inPoint: number ;
let outPoint: number = inPoint + targetHeight;			
export let homeScroll =  ()=> {
	if (document.getElementById("bgat")) {
				inPoint = bgat.getBoundingClientRect().top;
				outPoint = inPoint + targetHeight;
				scrollTop = window.pageYOffset;
				let windowAllHeight = scrollTop + windowHeight;
				(windowAllHeight > inPoint + scrollTop - 600 && scrollTop < outPoint + scrollTop + 300) ?	home.classList.add('is-active') :	home.classList.remove('is-active');
	}
};
