import { breakPointLG } from '../globalVar';
export class NavItem {
  selector: HTMLInputElement;
  onClick;
  constructor(_param) {
    this.selector = _param.selector;
    this.onClick = _param.onClick;
  }

  init() {
    this.addEvent();
  }

  addEvent() {
    let windowWidth: number = window.innerWidth;
    this.selector.addEventListener('click', (e) => {
      if (windowWidth < breakPointLG) {
        if (!this.selector.querySelector('a:not([href^="#"])')) {
          this.onClick('close');
          return false;
        }
      }
    });
  }

  open() {
    this.selector.classList.remove('is-close');
    this.selector.classList.add('is-open');
  }

  close() {
    this.selector.classList.remove('is-open');
    this.selector.classList.add('is-close');
  }
}
