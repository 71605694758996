import { breakPointMD } from '../globalVar.ts';
let mySwiper;
function topSwiperEvent() {
  if (mySwiper) {
    return;
  } else {
    mySwiper = new Swiper('.swiper-homeContainer', {
      loop: false, // 最後のスライドまで到達した場合、最初に戻らずに続けてスライド可能にするか。
      speed: 1000, // スライドが切り替わるトランジション時間(ミリ秒)。
      slidesPerView: 1.1, // 何枚のスライドを表示するか
      slidesPerGroup: 1,
      spaceBetween: 0, // スライド間の余白サイズ(ピクセル)
      direction: 'horizontal', // スライド方向。 'horizontal'(水平) か 'vertical'(垂直)。effectオプションが 'slide' 以外は無効。
      effect: 'slide', // "slide", "fade"(フェード), "cube"(キューブ回転), "coverflow"(カバーフロー) または "flip"(平面回転)
      centeredSlides: true,
      autoplay: {
        delay: 4000, // スライドが切り替わるまでの表示時間(ミリ秒)
        stopOnLast: false, // 最後のスライドまで表示されたら自動再生を中止するか
        disableOnInteraction: false, // ユーザーのスワイプ操作を検出したら自動再生を中止するか
      }, // ページネーションを表示する場合
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination', // ページネーションを表示するセレクタ
        clickable: true,
        type: 'fraction',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction: function (index) {
          return (
            '<span class="swiper-pagination-current">' +
            index +
            '</span>' +
            '／' +
            '<span class="swiper-pagination-total"></span>'
          );
        },
      },
    });
  }
}
export let topSwiper = ()=>{
	if(	window.document.body.id === 'home'){
		if (window.innerWidth < breakPointMD) {
			topSwiperEvent();
		} else {
			if (mySwiper) {
				mySwiper.destroy(false, true);
				mySwiper = undefined;
			}
		}
	}
};
