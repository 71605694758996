const hoverItems :any = document.querySelectorAll('.js-hover');
export let hoverEvent = ()=> {
  if (!('ontouchstart' in document) && !('orientation' in window)) {
		for (const hoverItem of hoverItems) {
			hoverItem.addEventListener('mouseenter', ()=> {
        hoverItem.classList.add('is-hover');
      });
			hoverItem.addEventListener('mouseleave', ()=> {
        hoverItem.classList.remove('is-hover');
      });
		}
  }
};
