import { firstWindowHeight } from '../globalVar';
let fadeIns:any= document.querySelectorAll('.js-fade');
let scrollTop: number;
let fadeTop: number;
let fadeTopChild: number;

export const fadeIn =  () => {
	if(document.querySelector('.js-fade')){

		for (const fadeIn of fadeIns) {
			fadeTop = Math.floor(fadeIn.getBoundingClientRect().top); //値の更新
			scrollTop = window.pageYOffset;

			if (scrollTop + firstWindowHeight / 1.05 > fadeTop + scrollTop) {
				fadeIn.classList.add('is-show');
				if (fadeIn.classList.contains('is-show') && fadeIn.querySelector('.js-fadeChild:not(.is-show)')) {
					let children = fadeIn.querySelectorAll('.js-fadeChild:not(.is-show)');
					let shows = fadeIn.querySelectorAll('.js-fadeChild.is-show');
					let showsLen: number = shows.length;
					setTimeout(() => {
						children.forEach((child, i) => {
							if (!child.classList.contains('is-show')) {
								fadeTopChild = Math.floor(child.getBoundingClientRect().top); //値の更新
								if (scrollTop + firstWindowHeight / 1.05 > fadeTopChild + scrollTop) {
									setTimeout(() => {
										child.classList.add('is-show');
									}, 300 * i - showsLen);
								}
							}
						});
					}, 900);

				}
			}
		}
	}
};
