(() => {
  //360以下は横スクロール
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    const value =
      window.outerWidth > 360
        ? "width=device-width,initial-scale=1"
        : "width=360";
    if (viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  }
  addEventListener("resize", switchViewport, false);
  switchViewport();
})();

import { firstWindowWidth } from "./globalVar";
import { breakPointLG } from "./globalVar";

import { headerInit } from "./header/main";
import { closeEvent } from "./header/main";
import { smoothScroll } from "./parts/smoothScroll";
import { hoverEvent } from "./parts/hoverEvent";
import { topSwiper } from "./parts/topSwiper.js";
import { heroHeight } from "./parts/heroHeight";
import { fadeIn } from "./parts/scrollFadeIn";
import { homeScroll } from "./parts/backgroundImageHome";
import { topContactEvent } from "./parts/topContact";
import { tableBtnEvent } from "./parts/tableBtn";
import { brAddAria } from "./parts/brAriaHidden.js";
// import { Form } from "./form/mainForm.js";


window.addEventListener("DOMContentLoaded", () => {
  const loaded = document.getElementById("load");
  brAddAria();

	if(document.getElementById("js-getHeight")){
let firstPromise = new Promise((resolve, reject) =>{
	resolve(loaded.classList.add("is-open"))
})
firstPromise.then(()=>{
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(heroHeight())
		}, 100);
	})
}).then(()=>{
	fadeIn();
}).then(()=>{
	headerInit();
	topContactEvent();
  homeScroll();
	hoverEvent();
  smoothScroll();
  tableBtnEvent();
  topSwiper();
});
	}else{
		let firstPromise = new Promise((resolve, reject) =>{
			resolve(loaded.classList.add("is-open"))
		})
		firstPromise.then(()=>{
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(fadeIn())
				}, 50);
			})
		}).then(()=>{
			headerInit();
			topContactEvent();
			homeScroll();
			hoverEvent();
			smoothScroll();
			tableBtnEvent();
			topSwiper();
		});
	}

//form
if(document.getElementById("js-contact-form")){
	const select:HTMLInputElement = <HTMLInputElement>document.getElementById('f-industry');
	let selectorColor = () => {
		select.addEventListener('change',()=>{
			if(select.value) {
				select.style.color = '#000';
			}else{
				select.style.color = '';
			}
		})
	}
	selectorColor();
}

//risize
		let timerResize: any = 0;
		clearTimeout(timerResize);
		timerResize = setTimeout(() => {
			window.addEventListener("resize", () => {
				let targetWidth = window.innerWidth;
				if (firstWindowWidth === targetWidth) {
					//ios対策横幅が変わった時のみ着火
					return;
				} else {
					heroHeight();
					topSwiper();
					(targetWidth > breakPointLG)?	closeEvent() : '';
				}
			});
		}, 500);
	
	
  // fadeIns;
  window.addEventListener("scroll", () => {
    let timer: any = 0;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fadeIn();
      topContactEvent();
      homeScroll();
    }, 100);
  });
});
